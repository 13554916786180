const navigationMobile = () => {
  $('#button--mobile').on('click', () => {
    $('body').toggleClass('menu-open');
    document.getElementById('navigation-overlay').style.height = $('body').hasClass('menu-open') ? '100%' : '0%';
  });
};

const navigationSize = () => {
  const position = $(window).scrollTop();
  if (position > 220) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > 220) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
};

const navigationDropdown = () => {
  const viewport = $(window).width();
  if (viewport < 576) {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      $(e.currentTarget).next().toggle();
      return false;
    });
  } else {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      const navSub = $(e.currentTarget).parent();
      const isFocus = navSub.hasClass('focus');
      $('#navigation-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      e.stopPropagation();
      return false;
    });
    $('html').on('click', () => {
      $('#navigation-main li[data-navsub]').removeClass('focus');
    });
  }
};

const scrollTop = () => {
  const domElm = $('#button--scroll-top');
  domElm.fadeOut(0);
  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > 220) {
      domElm.fadeIn(300);
    } else {
      domElm.fadeOut(300);
    }
  });
  domElm.on('click', () => {
    $('html, body').animate({
      scrollTop: 0,
    }, '500');
    return false;
  });
};

const reservationToggle = () => {
  const form = $('.form-resa-inner-page, .form-resa');
  const btn = $('.trigger-reservation-page');
  if($('#header-reservation').length){
    form.hide();
  }
  btn.on('click', () => {
    btn.toggleClass('open');
    if ($('#header-reservation') === null) {
      btn.parent().hide();
    }
    const headerReservationHeight = $('#header-reservation').innerHeight();
    form.show();
    setTimeout(() => {
      if($('.form-resa-inner-page').length){
        const positionForm = $('.form-resa-inner-page').offset().top - (headerReservationHeight === undefined
          ? 0 : headerReservationHeight);
        $('html, body').animate(
          {
            scrollTop: positionForm,
          },
          {
            duration: 500,
            complete() {
              setTimeout(() => {
                $('#datepicker-start').datepicker('show');
              }, 200);
            },
          },
        );
      }else{
        setTimeout(() => {
          $('#datepicker-start').datepicker('show');
        }, 200);
      }
    });

    return false;
  });
};

const reservationHeaderPadding = () => {
  const computePadding = () => {
    if ($('#header-reservation') !== null) {
      const headerReservationHeight = $('#header-reservation').innerHeight();
      $('body').css('paddingTop', headerReservationHeight);
    }
  };

  computePadding();
  $(window).on('resize', () => {
    computePadding();
  });
};

navigationMobile();
navigationDropdown();
navigationSize();
scrollTop();
reservationToggle();
reservationHeaderPadding();
